import React from "react"
import Layout from "@components/layout"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
// import WebriQForm from "@webriq/gatsby-webriq-form"

import useWindowSize from "../hooks/useWindowSize"

//lazyloading
import { LazyLoadComponent } from "react-lazy-load-image-component"

//images
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

//images and icons
// import LogoWhite from "@images/FF-white-header-logo.svg"
// import ShareIcon from "@images/share-icon.svg"
// import LinkedInIcon from "@images/linkedin-colored-icon.svg"
// import FacebookIcon from "@images/facebook-colored-icon.svg"
// import TwitterIcon from "@images/twitter-colored-icon.svg"
import HeartIcon from "@images/heart-icon.svg"
// import VideoIcon from "@images/video-icon-sm.svg"

const slugify = require("slugify")

// import sideMenuCat from "@components/categories/sidemenu-categories.js"

const BlogAuthor = ({ location, data }) => {
  // const posts = data.allSanityPost.edges

  const size = useWindowSize()

  const { category, author } = data

  const authorPerson = author

  // console.log("author gyud ni siya", authorPerson)

  const featuredCat = data.allSanityCategory.nodes

  const latestPosts = author.edges

  // const filterTrending = category.group.filter(
  //   fltr => fltr.fieldValue === "Trending"
  // )

  const filterResearch = category.group.filter(
    fltr => fltr.fieldValue === "Thought Provoking"
  )

  // const filterAuthor = author.group.filter(
  //   fltr => fltr.authors[0].person.name === fltr.authors.person.name
  // )

  return (
    <Layout location={location}>
      <Seo title={`${data.sanityPerson.name} | Fluo Foundation`} />
      <div style={{ marginTop: "9.5rem" }}>
        {/* <div className="my-5 py-3">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ backgroundColor: "pink" }}
            >
              This area is for the BREADCRUMBS
            </div>
          </div>
        </div> */}

        <LazyLoadComponent>
          <div style={{ paddingBottom: "10rem" }}>
            <div className="container">
              <LazyLoadComponent>
                <div className="p-4 mt-5 bg-light rounded-25 my-5">
                  <div className="row">
                    <div className="col-md-2 text-center mb-3 mb-lg-0">
                      <GatsbyImage
                        image={data.sanityPerson?.image?.asset?.gatsbyImageData}
                        style={{
                          gridArea: "1/1",
                          zIndex: 1,
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                        }}
                        className="mx-auto"
                      />
                      <div
                        style={{
                          gridArea: "1/1",
                          placeItems: "center",
                        }}
                      />
                    </div>
                    <div className="col-md-10 text-center text-md-start">
                      <h2 className="m-0" style={{ fontWeight: 700 }}>
                        {data.sanityPerson.name}
                      </h2>
                      <span
                        className="text-muted text-uppercase small"
                        style={{ fontWeight: 600 }}
                      >
                        About the Author
                      </span>
                      <p className="my-4">
                        {data.sanityPerson?.bio && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.sanityPerson?.bio[0]?.children[0]?.text,
                            }}
                          />
                        )}
                      </p>
                      {/* <div className="socials-inline">
                        <a
                          href="/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <img src={FacebookIcon} alt="Facebook" />
                        </a>
                        <a
                          href="/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <img src={TwitterIcon} alt="Twitter" />
                        </a>
                        <a href="/" target="_blank" rel="noreferrer">
                          <img src={LinkedInIcon} alt="LinkedIn" />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </LazyLoadComponent>
              <span>Posts written by</span>
              <div className="row justify-content-between responsive-gutter">
                <LazyLoadComponent>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                    <div className="d-grid gap-5">
                      <h1
                        className="display-4 bordered-heading"
                        style={{ fontWeight: 700 }}
                      >
                        {data.sanityPerson.name}
                      </h1>
                      {authorPerson.edges.map(({ node }, index) => {
                        // console.log("index", index)
                        if (index === 0) {
                          return (
                            <div>
                              <div
                                className="blog-item blog-item-overlay-text d-grid"
                                key={node.id}
                              >
                                <Link
                                  to={`/${node.slug.current}`}
                                  className="link-unstyled d-grid"
                                >
                                  <div className="blog-image-wrapper image-wrapper-rectangle rounded-25">
                                    <GatsbyImage
                                      style={{
                                        gridArea: "1/1",
                                        zIndex: 1,
                                        borderRadius: 25,
                                        width: "100%",
                                      }}
                                      image={
                                        node.mainImage
                                          ? node.mainImage.asset.gatsbyImageData
                                          : ""
                                      }
                                      alt={node.title}
                                    />
                                    <div
                                      style={{
                                        gridArea: "1/1",
                                        placeItems: "center",
                                      }}
                                    />
                                    {/* <div
                                      className="blog-item-type"
                                      title="video"
                                      // style={{ zIndex: 3 }}
                                    >
                                      <img src={VideoIcon} alt="Video Icon" />
                                    </div> */}
                                  </div>
                                </Link>
                              </div>
                              <div className="blog-text mt-5">
                                <div className="blog-item-categories">
                                  {/* <Link to="/" className="blog-category-tag">
                                <span style={{ background: "#00c4ff" }}></span>
                                business
                              </Link>
                              <Link to="/" className="blog-category-tag">
                                <span style={{ background: "#9e5cf1" }}></span>
                                creative
                              </Link> */}
                                  {node?.categories?.map(cat => (
                                    <Link
                                      to={`/category/${slugify(
                                        cat.title.toLowerCase()
                                      )}`}
                                      className="blog-category-tag"
                                    >
                                      <span
                                        className={`category-color ${(cat.title ===
                                          "Business" &&
                                          "business-color") ||
                                          (cat.title === "Creative" &&
                                            "creative-color") ||
                                          (cat.title === "Newsworthy" &&
                                            "news-color") ||
                                          (cat.title === "Strategy" &&
                                            "strategy-color") ||
                                          "default-color"}`}
                                      />
                                      {cat.title}
                                    </Link>
                                  ))}
                                </div>
                                <Link
                                  to={`/${node.slug.current}`}
                                  className="link-unstyled"
                                >
                                  <h3
                                    className="h4 mt-2 mb-3 blog-item-title"
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    {node.title}
                                  </h3>
                                </Link>
                                <span className="d-block small blog-item-details">
                                  {node.authors.length !== 0 ? "Posted by" : ""}
                                  {node.authors.map(author => (
                                    <Link
                                      to={`/author/${author?.person?.slug.current}`}
                                      className="link-unstyled me-2"
                                      style={{ fontWeight: 700 }}
                                    >
                                      <span className="ps-2 pe-2">
                                        {author?.person?.name}
                                      </span>
                                      <span>•</span>
                                    </Link>
                                  ))}
                                  {node?.publishedAt ? (
                                    <div className="d-inline-block">
                                      <span>{node?.publishedAt}</span>
                                    </div>
                                  ) : null}
                                  {node?.minutes ? (
                                    <span className="ms-2">
                                      <span className="me-2">•</span>
                                      {node?.minutes} min read
                                    </span>
                                  ) : null}
                                </span>
                                <p className="my-3">{node.excerpt}</p>
                                <Link
                                  to={`/` + node.slug.current + `/`}
                                  className="btn btn-primary"
                                  role="button"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <LazyLoadComponent>
                              {/* {filterAuthor.map(posts => ( */}
                              <div className="row blog-item">
                                <div className="col-md-5">
                                  <Link
                                    to={`/${node.slug.current}`}
                                    className="link-unstyled"
                                  >
                                    <div
                                      className="blog-image-wrapper image-wrapper-square"
                                      style={{ borderRadius: "25px" }}
                                    >
                                      <GatsbyImage
                                        image={
                                          node.mainImage
                                            ? node.mainImage.asset
                                                .gatsbyImageData
                                            : ""
                                        }
                                        style={{
                                          gridArea: "1/1",
                                          zIndex: 1,
                                          position: "absolute",
                                          height: "100%",
                                        }}
                                      />
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-md-7">
                                  <div className="blog-text">
                                    <div className="blog-item-categories">
                                      {node?.categories?.map(cat => (
                                        <Link
                                          to={`/category/${slugify(
                                            cat.title.toLowerCase()
                                          )}`}
                                          className="blog-category-tag"
                                        >
                                          <span
                                            className={`category-color ${(cat.title ===
                                              "Business" &&
                                              "business-color") ||
                                              (cat.title === "Creative" &&
                                                "creative-color") ||
                                              (cat.title === "Newsworthy" &&
                                                "news-color") ||
                                              (cat.title === "Strategy" &&
                                                "strategy-color") ||
                                              "default-color"}`}
                                          />
                                          {cat.title}
                                        </Link>
                                      ))}
                                    </div>
                                    <Link
                                      to={`/${node.slug.current}`}
                                      className="link-unstyled"
                                    >
                                      <h3
                                        className="
                          h4
                          mt-2
                          mb-3
                          blog-item-title
                        "
                                        style={{ fontWeight: 600 }}
                                      >
                                        {node.title}
                                      </h3>
                                    </Link>
                                    <span className="d-block small blog-item-details">
                                      {node.authors.length !== 0
                                        ? "Posted by"
                                        : ""}
                                      {node.authors.map(author => (
                                        <Link
                                          to={`/author/${author?.person?.slug.current}`}
                                          className="link-unstyled me-2"
                                          style={{ fontWeight: 700 }}
                                        >
                                          <span className="ps-2 pe-2">
                                            {author?.person?.name}
                                          </span>
                                          <span>•</span>
                                        </Link>
                                      ))}
                                      {node?.publishedAt ? (
                                        <div className="d-inline-block">
                                          <span>{node?.publishedAt}</span>
                                        </div>
                                      ) : null}
                                      {node?.minutes ? (
                                        <span className="ms-2">
                                          <span className="me-2">•</span>
                                          {node?.minutes} min read
                                        </span>
                                      ) : null}
                                    </span>
                                    <p className="my-3">{node.excerpt}</p>
                                    <Link
                                      to={`/` + node.slug.current + `/`}
                                      className="btn btn-primary"
                                      role="button"
                                    >
                                      Read More
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              {/* ))} */}
                            </LazyLoadComponent>
                          )
                        }
                      })}
                    </div>
                  </div>
                </LazyLoadComponent>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 sidebar-column pt-xl-0 pt-lg-0 pt-md-5 pt-sm-3 pt-3">
                  <LazyLoadComponent>
                    {size.width <= "768.99" ? (
                      <div className="mb-5 mt-5">
                        <div>
                          <div className="px-4 py-5 bg-dark-blue text-white text-center rounded-25">
                            <h2 className="h5" style={{ fontWeight: 700 }}>
                              {/* Subscribe to Our Newsletter */}
                              Sign-up for Our Newsletter!
                            </h2>
                            {/* <p className="small">
                      Get our latest updates straight to your inbox!
                    </p> */}
                            <iframe
                              title="Subscribe"
                              scrolling="no"
                              src="https://www.forms.fluofoundation.org/szajtg"
                              style={{
                                border: "0px;",
                                width: "100%",
                                height: "120px",
                              }}
                            ></iframe>
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="cta-box donation-cta text-center text-white bg-dark rounded-25">
                            <StaticImage
                              style={{
                                gridArea: "1/1",
                                borderRadius: 25,
                                zIndex: 1,
                                content: "",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              layout="fullWidth"
                              placeholder="blurred"
                              // aspectRatio={3 / 1}
                              alt="Category Banner"
                              src={"../images/donation-cta-bg.png"}
                              formats={["auto", "webp", "avif"]}
                            />
                            <div className="content-wrapper">
                              <h2 style={{ fontWeight: 700 }}>
                                Make a Donation
                              </h2>
                              <p className="mt-4 mb-4">
                                Driven by a bold vision for the profound
                                transformation in the mental health of young
                                individuals, the FLUO Foundation relies on the
                                charitable donations of those who are ready to
                                rally behind our youth.
                              </p>
                              <Link
                                className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                                role="button"
                                href="/donate.html"
                                style={{
                                  width: 186,
                                  height: 54,
                                  fontWeight: 600,
                                }}
                              >
                                Donate
                                <img
                                  src={HeartIcon}
                                  alt="Heart Icon"
                                  className="ms-2"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </LazyLoadComponent>
                  <LazyLoadComponent>
                    <div className="d-grid gap-5 pt-mobile-spacing">
                      <h2
                        className="display-6 bordered-heading"
                        style={{ fontWeight: "bold" }}
                      >
                        Categories
                      </h2>
                      <div className="d-grid gap-3">
                        {featuredCat.map(featCat => (
                          <Link
                            to={`/category/${slugify(
                              featCat.title.toLowerCase()
                            )}`}
                            className="category-banner-link link-unstyled"
                          >
                            {(featCat.title === "Thought Provoking" && (
                              <span className="business-cat">
                                <StaticImage
                                  style={{
                                    gridArea: "1/1",
                                    zIndex: 1,
                                    borderRadius: 10,
                                    content: "",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  layout="fullWidth"
                                  placeholder="blurred"
                                  // aspectRatio={3 / 1}
                                  alt="Category Banner"
                                  src={"../images/pexels-burst-374720.jpg"}
                                  formats={["auto", "webp", "avif"]}
                                />
                              </span>
                            )) ||
                              (featCat.title === "Newsworthy" && (
                                <span className="news-cat">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={
                                      "../images/pexels-daria-shevtsova-1928151.jpeg"
                                    }
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title && (
                                <span className="business-cat">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/pexels-burst-374720.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              ))}
                            <div
                              style={{
                                gridArea: "1/1",
                                placeItems: "center",
                              }}
                            />
                            <div className="blog-category-tag">
                              <Link
                                to={`/category/${slugify(
                                  featCat.title.toLowerCase()
                                )}`}
                              >
                                <span
                                  className={`category-color ${(featCat.title ===
                                    "Business" &&
                                    "business-color") ||
                                    (featCat.title === "Thought Provoking" &&
                                      "business-color") ||
                                    (featCat.title === "Creative" &&
                                      "creative-color") ||
                                    (featCat.title === "Newsworthy" &&
                                      "news-color") ||
                                    (featCat.title === "Strategy" &&
                                      "strategy-color") ||
                                    "default-color"}`}
                                />
                                {featCat.title}
                              </Link>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </LazyLoadComponent>
                  <LazyLoadComponent>
                    <div className="d-grid gap-5 pt-spacing">
                      <h2
                        className="display-6 bordered-heading"
                        style={{ fontWeight: 700 }}
                      >
                        Latest posts
                      </h2>
                      {latestPosts.slice(0, 2).map(post => (
                        <div className="blog-item">
                          <div className="blog-text">
                            <div className="blog-item-categories">
                              {post?.node?.categories?.map(cat => (
                                <Link
                                  to={`/category/${slugify(
                                    cat.title.toLowerCase()
                                  )}`}
                                  className="blog-category-tag"
                                >
                                  <span
                                    className={`category-color ${(cat.title ===
                                      "Business" &&
                                      "business-color") ||
                                      (cat.title === "Creative" &&
                                        "creative-color") ||
                                      (cat.title === "Newsworthy" &&
                                        "news-color") ||
                                      (cat.title === "Strategy" &&
                                        "strategy-color") ||
                                      "default-color"}`}
                                  />
                                  {/* {posts?.node?.categories.title} */}
                                  {cat.title}
                                </Link>
                              ))}
                            </div>
                            <Link
                              to={`/${post?.node?.slug?.current}`}
                              className="link-unstyled"
                            >
                              <h3
                                className="h4 mt-2 mb-3 blog-item-title"
                                style={{ fontWeight: 600 }}
                              >
                                {post.node.title}
                              </h3>
                            </Link>
                            <span className="d-block small blog-item-details">
                              {post.node.authors.length !== 0
                                ? "Posted by"
                                : ""}
                              {post.node.authors.map(author => (
                                <Link
                                  to={`/author/${author?.person?.slug.current}`}
                                  className="link-unstyled me-2"
                                  style={{ fontWeight: 700 }}
                                >
                                  <span className="ps-2 pe-2">
                                    {author?.person?.name}
                                  </span>
                                  <span style={{ color: "#000" }}>•</span>
                                </Link>
                              ))}
                              {post?.node?.publishedAt ? (
                                <div className="d-inline-block">
                                  <span>{post?.node?.publishedAt}</span>
                                </div>
                              ) : null}
                              {post?.node?.minutes ? (
                                <span className="ms-2">
                                  <span className="me-2">•</span>
                                  {post?.node?.minutes} min read
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </LazyLoadComponent>
                  <div className="d-xl-block d-lg-block d-md-block d-sm-none d-none">
                    <LazyLoadComponent>
                      <div className="pt-spacing">
                        <div className="px-4 py-5 bg-dark-blue text-white text-center rounded-25">
                          <h2 className="h5" style={{ fontWeight: 700 }}>
                            {/* Subscribe to Our Newsletter */}
                            Sign-up for Our Newsletter!
                          </h2>
                          {/* <p className="small">
                      Get our latest updates straight to your inbox!
                    </p> */}
                          <iframe
                            title="Subscribe"
                            scrolling="no"
                            src="https://www.forms.fluofoundation.org/szajtg"
                            style={{
                              border: "0px;",
                              width: "100%",
                              height: "120px",
                            }}
                          ></iframe>
                        </div>
                      </div>
                    </LazyLoadComponent>
                    <LazyLoadComponent>
                      <div className="pt-spacing">
                        <div className="cta-box donation-cta text-center text-white bg-dark rounded-25">
                          <StaticImage
                            style={{
                              gridArea: "1/1",
                              borderRadius: 25,
                              zIndex: 1,
                              content: "",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            layout="fullWidth"
                            placeholder="blurred"
                            // aspectRatio={3 / 1}
                            alt="Category Banner"
                            src={"../images/donation-cta-bg.png"}
                            formats={["auto", "webp", "avif"]}
                          />
                          <div
                            className="content-wrapper"
                            style={{ padding: "1rem" }}
                          >
                            <h2 style={{ fontWeight: 700 }}>Make a Donation</h2>
                            {/* <p className="mt-4 mb-4">
                            While 1 in 5 people will experience a mental illness
                            during their lifetime, everyone faces challenges in
                            life that can impact their mental health. Join us in
                            supporting the effort in promoting mental health for
                            all!
                          </p> */}
                            <p className="mt-4 mb-4">
                              {/* Together we are amplifying a vibration that
                              creates and inspires a huge impact far beyond
                              anything we could ever imagine. Making a donation
                              by clicking the button below will go directly to
                              helping a youth in need, one child at a time. */}
                              Driven by a bold vision for the profound
                              transformation in the mental health of young
                              individuals, the FLUO Foundation relies on the
                              charitable donations of those who are ready to
                              rally behind our youth.
                            </p>
                            <Link
                              className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                              role="button"
                              href="/donate.html"
                              style={{
                                width: 186,
                                height: 54,
                                fontWeight: 600,
                              }}
                            >
                              Donate
                              <img
                                src={HeartIcon}
                                alt="Heart Icon"
                                className="ms-2"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </LazyLoadComponent>
                  </div>
                  <LazyLoadComponent>
                    <div className="d-grid gap-5 pt-spacing">
                      <h2
                        className="display-6 bordered-heading"
                        style={{ fontWeight: 700 }}
                      >
                        Trending
                      </h2>
                      {filterResearch[0].edges.slice(0, 2).map(trending => (
                        <div className="blog-item">
                          <Link to={`/${trending.node.slug.current}`}>
                            <div
                              className="
                      blog-image-wrapper
                      image-wrapper-rectangle
                      mb-3
                      rounded-25
                    "
                            >
                              <GatsbyImage
                                image={
                                  trending.node.mainImage
                                    ? trending.node.mainImage.asset
                                        .gatsbyImageData
                                    : ""
                                }
                                alt="Trending Post Img"
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 25,
                                  position: "absolute",
                                  content: "",
                                  height: "100%",
                                  width: "100%",
                                  top: 0,
                                  left: 0,
                                }}
                              />
                              {/* <div className="blog-item-type" title="video">
                                <img src={VideoIcon} alt="Video icon" />
                              </div> */}
                            </div>
                          </Link>
                          <div className="blog-text">
                            <div className="blog-item-categories">
                              {trending?.node?.categories?.map(cat => (
                                <Link
                                  to={`/category/${slugify(
                                    cat.title.toLowerCase()
                                  )}`}
                                  className="blog-category-tag"
                                >
                                  <span
                                    className={`category-color ${(cat.title ===
                                      "Business" &&
                                      "business-color") ||
                                      (cat.title === "Creative" &&
                                        "creative-color") ||
                                      (cat.title === "Newsworthy" &&
                                        "news-color") ||
                                      (cat.title === "Strategy" &&
                                        "strategy-color") ||
                                      "default-color"}`}
                                  />
                                  {cat.title}
                                </Link>
                              ))}
                            </div>
                            <Link
                              to={`/${trending.node.slug.current}`}
                              className="link-unstyled"
                            >
                              <h3
                                className="h4 mt-2 mb-3 blog-item-title"
                                style={{ fontWeight: "600" }}
                              >
                                {trending.node.title}
                              </h3>{" "}
                            </Link>
                            <span className="d-block small blog-item-details">
                              {trending.node.authors.length !== 0
                                ? "Posted by"
                                : ""}
                              {trending.node.authors.map(author => (
                                <Link
                                  to={`/author/${author?.person?.slug.current}`}
                                  className="link-unstyled me-2"
                                  style={{ fontWeight: 700 }}
                                >
                                  <span className="ps-2 pe-2">
                                    {author?.person?.name}
                                  </span>
                                  <span>•</span>
                                </Link>
                              ))}
                              {trending?.node?.publishedAt ? (
                                <div className="d-inline-block">
                                  <span>{trending?.node?.publishedAt}</span>
                                </div>
                              ) : null}
                              {trending?.node?.minutes ? (
                                <span className="ms-2">
                                  <span className="me-2">•</span>
                                  {trending?.node?.minutes} min read
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </LazyLoadComponent>
                </div>
              </div>
            </div>
          </div>
        </LazyLoadComponent>
        {/* <sideMenuCat /> */}
      </div>
    </Layout>
  )
}

export default BlogAuthor

export const authorQuery = graphql`
  query authorQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    sanityPerson(id: { eq: $id }) {
      name
      bio {
        children {
          text
        }
      }
      image {
        asset {
          gatsbyImageData
        }
      }
    }
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          title
          minutes
          categories {
            featuredCategories
            title
          }
          slug {
            current
          }
          authors {
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
    author: allSanityPost(
      filter: { authors: { elemMatch: { person: { id: { eq: $id } } } } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          minutes
          title
          excerpt
          categories {
            featuredCategories
            title
          }
          authors {
            person {
              name
              slug {
                current
              }
            }
          }
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
      group(field: authors___person___name) {
        edges {
          node {
            id
            title
            minutes
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            publishedAt
            excerpt
            categories {
              title
            }
          }
        }
        fieldValue
      }
    }
    category: allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            minutes
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            publishedAt(formatString: "MMMM DD, YYYY")
            excerpt
            categories {
              title
            }
          }
        }
      }
    }
    allSanityCategory(
      filter: { featuredCategories: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        featuredCategories
        title
      }
    }
  }
`
